import analyticsSaga from 'modules/analytics/store/analytics-saga';
import { clientsSaga } from 'modules/client/store/clients';
import contactsSaga from 'modules/contact/store/contacts-saga';
import commentTemplatesSaga from 'modules/comment-templates/store/comment-templates-saga';
import customerSaga from 'modules/customer/store/customers-saga';
import customerSelectSaga from 'modules/customer/store/customerSelect-saga';
import inboxSaga from 'modules/inbox/store/inbox-saga';
import emailLogsSaga from 'modules/log/store/email-logs-saga';
import productsSaga from 'modules/product/store/products-saga';
import productTypesSaga from 'modules/product/store/productTypes-saga';
import { tasksSaga } from 'modules/project/store/task';
import { tasksPersonalSaga } from 'modules/project/store/tasksPersonal';
import { projectTasksSaga as tableProjectTasksSaga } from 'modules/project/store/projectTasks';
import { projectWorkLogsSaga } from 'modules/project/store/projectWorkLogs';
import { projectsSaga } from 'modules/project/store/project';
import { projectsAdvertisingSaga } from 'modules/advertising/store/projectAdvertising';
import { projectsAnalyticsSaga } from 'modules/analytics/store/projectAnalytics';
import { projectsTimeReportSaga } from 'modules/projectTimeReport/store/projectTimeReport';
import projectSelectSaga from 'modules/project/store/projectSelect-saga';
import projectTypeSelectSaga from 'modules/project/store/projectTypeSelect-saga';
import permissionsSaga from 'modules/role/store/permissions-saga';
import rolesSaga from 'modules/role/store/roles-saga';
import groupsSaga from 'modules/groups/store/groups-saga';
import formsSaga from 'modules/forms/store/sagas';
import bluePrintsSaga from 'modules/forms/store/blueprints/bluePrints-saga';
import answersSaga from 'modules/forms/store/answers/sagas';
import contentSaga from 'modules/content/store/content-saga';
import { timelogReportSaga } from 'modules/timelogReport/store/timelogReport';
import { projectTimelogReportSaga } from 'modules/project/store/projectTimelogReport';
import administrationSaga from 'modules/administration/store/administration-saga';
import dashboardProjectsSaga from 'modules/dashboard/store/dashboardProjects-saga';
import dashboardProjectLogsSaga from 'modules/dashboard/store/dashboardProjectLogs-saga';
import dashboardPastDueSaga from 'modules/dashboard/store/dashboardPastDue-saga';
import dashboardDueTodaySaga from 'modules/dashboard/store/dashboardDueToday-saga';
import dashboardRecentTasksSaga from 'modules/dashboard/store/dashboardRecentTasks-saga';
import dashboardFailedPaymentsSaga from 'modules/dashboard/store/dashboardFailedPayments-saga';

import { all, fork } from 'redux-saga/effects';

import activeLogsSaga from './container/activeLogs/activeLogs-sagas';
import authSaga from './container/auth/auth-sagas';

export default function* root() {
  yield all([
    fork(contactsSaga),
    fork(commentTemplatesSaga),
    fork(customerSaga),
    fork(customerSelectSaga),
    fork(clientsSaga),
    fork(authSaga),
    fork(permissionsSaga),
    fork(rolesSaga),
    fork(groupsSaga),
    fork(emailLogsSaga),
    fork(productsSaga),
    fork(tasksSaga),
    fork(tasksPersonalSaga),
    fork(tableProjectTasksSaga),
    fork(projectsSaga),
    fork(projectsAdvertisingSaga),
    fork(projectsAnalyticsSaga),
    fork(projectsTimeReportSaga),
    fork(projectWorkLogsSaga),
    fork(projectSelectSaga),
    fork(projectTypeSelectSaga),
    fork(analyticsSaga),
    fork(inboxSaga),
    fork(activeLogsSaga),
    fork(timelogReportSaga),
    fork(projectTimelogReportSaga),
    fork(contentSaga),
    fork(formsSaga),
    fork(answersSaga),
    fork(productTypesSaga),
    fork(administrationSaga),
    fork(bluePrintsSaga),
    fork(dashboardProjectLogsSaga),
    fork(dashboardProjectsSaga),
    fork(dashboardPastDueSaga),
    fork(dashboardDueTodaySaga),
    fork(dashboardRecentTasksSaga),
    fork(dashboardFailedPaymentsSaga),
  ]);
}
