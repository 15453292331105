import { clientsReducer } from 'modules/client/store/clients';
import contacts from 'modules/contact/store/contacts-slice';
import commentTemplates from 'modules/comment-templates/store/comment-templates-slice';
import customers from 'modules/customer/store/customers-slice';
import customerSelect from 'modules/customer/store/customerSelect-slice';
import inbox from 'modules/inbox/store/inbox-slice';
import emailLogs from 'modules/log/store/email-logs-slice';
import products from 'modules/product/store/products-slice';
import productTypes from 'modules/product/store/productTypes-slice';
import { projectsReducer } from 'modules/project/store/project';
import { projectsAdvertisingReducer } from 'modules/advertising/store/projectAdvertising';
import { projectsAnalyticsReducer } from 'modules/analytics/store/projectAnalytics';
import { projectsTimeReportReducer } from 'modules/projectTimeReport/store/projectTimeReport';
import { projectTasksReducer } from 'modules/project/store/projectTasks';
import { projectWorkLogsReducer } from 'modules/project/store/projectWorkLogs';
import projectSelect from 'modules/project/store/projectSelect-slice';
import projectTypeSelect from 'modules/project/store/projectTypeSelect-slice';
import analytics from 'modules/analytics/store/analytics-slice';
import { tasksReducer } from 'modules/project/store/task';
import { tasksPersonalReducer } from 'modules/project/store/tasksPersonal';
import permissions from 'modules/role/store/permissions-slice';
import forms from 'modules/forms/store/reducer';
import bluePrints from 'modules/forms/store/blueprints/bluePrints-slice';
import answers from 'modules/forms/store/answers/reducer';
import roles from 'modules/role/store/roles-slice';
import groups from 'modules/groups/store/groups-slice';
import content from 'modules/content/store/content-slice';
import { timelogReportReducer } from 'modules/timelogReport/store/timelogReport';
import { projectTimelogReportReducer } from 'modules/project/store/projectTimelogReport';
import administration from 'modules/administration/store/administration-slice';
import dashboardProjectLogs from 'modules/dashboard/store/dashboardProjectLogs-slice';
import dashboardProjects from 'modules/dashboard/store/dashboardProjects-slice';
import dashboardRecentTasks from 'modules/dashboard/store/dashboardRecentTasks-slice';
import dashboardFailedPayments from 'modules/dashboard/store/dashboardFailedPayments-slice';
import dashboardDueToday from 'modules/dashboard/store/dashboardDueToday-slice';
import dashboardPastDue from 'modules/dashboard/store/dashboardPastDue-slice';

import activeLogs from './container/activeLogs/activeLogs-slice';
import auth from './container/auth/auth-slice';
import versionUpdate from './container/versionUpdate/versionUpdate-slice';
import globalLog from './container/globalLog/globalLog-slice';

export default {
  contacts,
  commentTemplates,
  customers,
  customerSelect,
  roles,
  groups,
  inbox,
  auth,
  emailLogs,
  permissions,
  products,
  analytics,
  projectSelect,
  projectTypeSelect,
  tableTasks: tasksReducer,
  tableTasksPersonal: tasksPersonalReducer,
  tableProjectTasks: projectTasksReducer,
  tableProjects: projectsReducer,
  projectsAdvertising: projectsAdvertisingReducer,
  projectsAnalytics: projectsAnalyticsReducer,
  projectsTimeReport: projectsTimeReportReducer,
  tableProjectWorkLogs: projectWorkLogsReducer,
  clients: clientsReducer,
  activeLogs,
  timelogReport: timelogReportReducer,
  projectTimelogReport: projectTimelogReportReducer,
  globalLog,
  content,
  forms,
  answers,
  productTypes,
  bluePrints,
  administration,
  dashboardProjectLogs,
  dashboardProjects,
  dashboardDueToday,
  dashboardPastDue,
  dashboardFailedPayments,
  versionUpdate,
  dashboardRecentTasks,
};
