import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { IoBug } from 'react-icons/io5';
import { GrResources } from 'react-icons/gr';
import { VscGitPullRequestGoToChanges } from 'react-icons/vsc';
import { MdOutlineRestore } from 'react-icons/md';
import { feedbackIntegration } from '@sentry/react';
import { Dropdown, Menu, Button } from 'antd';
import { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import UserAvatar from 'components/UserAvatar';
import appRoutes from 'config/appRoutes';
import { logout } from 'store/container/auth/auth-slice';
import style from './index.module.less';

const sentryFeedback = feedbackIntegration({
  colorScheme: 'system',
  autoInject: false,
});

const sentryWidget = sentryFeedback.createWidget();

sentryWidget.el.style.display = 'none';

interface UserDropdownProps {
  user: any;
}

const UserDropdown: FC<UserDropdownProps> = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Dropdown
      placement="bottomRight"
      trigger={[isMobile ? 'click' : 'hover']}
      overlayClassName={style.overlay}
      overlay={
        <Menu className={style.menu}>
          <Menu.Item key="profile" icon={<ProfileOutlined />}>
            <Link to={`${appRoutes.CONTACTS}/${user?.contact?.uuid}/edit`}>Profile</Link>
          </Menu.Item>

          <Menu.Item
            key="bug"
            icon={<IoBug />}
            onClick={() => {
              sentryWidget.el.click();
            }}
          >
            Report bug
          </Menu.Item>

          <Menu.Item key="resource" icon={<GrResources />}>
            <a href="https://savageglobalmarketing.typeform.com/to/pxuyeRl2" target="_blank" rel="noreferrer">
              Request a new resource
            </a>
          </Menu.Item>

          <Menu.Item key="software" icon={<VscGitPullRequestGoToChanges />}>
            <a href="https://savageglobalmarketing.typeform.com/to/pqS014bt" target="_blank" rel="noreferrer">
              Request new software
            </a>
          </Menu.Item>

          <Menu.Item key="time-off" icon={<MdOutlineRestore />}>
            <a href="https://savageglobalmarketing.typeform.com/to/yZFxyRL5" target="_blank" rel="noreferrer">
              Request time-off
            </a>
          </Menu.Item>

          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={(event) => {
              event.domEvent.stopPropagation();
              event.domEvent.preventDefault();
              dispatch(
                logout({
                  onLoaded: () => history.push(appRoutes.LOGIN),
                }),
              );
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      }
    >
      <Button type="link" size="large" style={{ padding: 0 }}>
        <UserAvatar size="large" />
      </Button>
    </Dropdown>
  );
};

export default UserDropdown;
